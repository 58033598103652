import React from "react";
import ReactDOM from "react-dom";
import Constructor from "../components/constructor";

class BlogCreate {
    constructor() {
        this.events();
    }

    events() {
        let form = $('#blog-form'),
            picture = form.find('.file-preview'),
            preview = form.find('[file-preview]'),
            fileInputGroup = form.find('[file-input]');

        $(document)
            .on('change', '#blog-form .image-input', function (e) {
                // Изменение превью
                e.stopPropagation();
                if (this.files && this.files[0]) {
                    form.find('[file-group-preview]').slideDown(100);
                    preview.slideDown(400);
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        picture.attr('src', e.target.result);
                    }
                    reader.readAsDataURL(this.files[0]);
                    form.find('input[name="remove_image"]').val(0);
                    form.find('[file-preview-delete]').slideDown(400);
                    fileInputGroup.slideUp(400);
                    form.find('[hide-preview]').slideDown(400);

                } else {
                    form.find('[file-group-preview]').slideUp(500);
                    form.find('[file-preview-delete]').slideUp(400);
                    preview.slideUp(400);
                    picture.attr('src', '');
                    form.find('[hide-preview]').slideUp(400);
                    fileInputGroup.slideDown(400);
                }
            })
            .on('click', '[blog-create]', () => {
                // Открыть Popup "Создать публикацию"
                ReactDOM.render(<Constructor name="text" />, document.getElementById("constructor"));
                $('[create-modal]').modal('show');
            })
            .on('click', '[blog-create-close]', () => {
                // Закрыть Popup "Создать публикацию"
                this.closeModal();
            })
            .on('click', '#blog-form [file-preview-delete]', function (e) {
                // Удаление превью
                e.stopPropagation();
                $(e.currentTarget).slideUp(400);
                form.find('[file-group-preview]').slideUp(500);
                preview.slideUp(400);
                picture.attr('src', '');
                fileInputGroup.slideDown(400);
                form.find('input[name="remove_image"]').val(1);
                form.find('.image-input').replaceWith($('#blog-form .image-input').clone().val(''));
                form.find('.image-input').trigger('change').trigger('input');
                form.find('[file-change-name] [delete]').trigger('click');
                form.find('[hide-preview]').slideUp(400);
            });
    }

    closeModal() {
        $('[create-modal]').modal('hide');
        $('#blog-form').trigger('reset');
        ReactDOM.unmountComponentAtNode(document.querySelector("#blog-form #constructor"));
    }
}

export default BlogCreate;