import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(
                stateFromHTML(this.props.value ? this.props.value : ''),
            ),
            value: this.props.value
        };
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            this.onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            value: stateToHTML(editorState.getCurrentContent(), {
                entityStyleFn: (entity) => {
                    if (entity.get('type').toLowerCase() === 'link') {
                        return {
                            element: 'a',
                            attributes: {
                                href: entity.getData().url,
                                target: '_blank'
                            }
                        };
                    }
                }
            })
        }, () => {
            if (typeof this.props.onInput === 'function') {
                this.props.onInput();
            }
        });
    };

    render() {
        const { editorState } = this.state;
        return (
            <>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper constructor-wrapper"
                    editorClassName="demo-editor constructor-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        options: ['inline', 'blockType', 'list', 'link', 'emoji', 'remove', 'history'],
                        inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        list: {
                            options: ['unordered', 'ordered']
                        },
                    }}
                    localization={{
                        locale: 'ru',
                    }}
                    placeholder="Введите текст..."
                />
                <textarea
                    name={this.props.name}
                    value={this.state.value}
                    onChange={() => { }}
                    style={{ display: 'none' }}
                ></textarea>
                {
                    this.props.errors
                        ? <div className="invalid-feedback">{this.props.errors[0]}</div>
                        : ''
                }
            </>
        )
    }
}

export default TextEditor;