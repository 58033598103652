import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import File from "./file";
import Input from "./input";
import Poll from "./poll";
import TextEditor from "./texteditor";
import CustomField from "./customField";
require('./helpers');

class Constructor extends React.Component {

    constructor(props) {
        super(props);

        this.fieldsToCreate = [
            // {
            //     type: 'string',
            //     placeholder: 'Строка',
            //     description: 'Однострочное текстовое поле',
            //     svg: '<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="45" height="45" rx="7.5" stroke="#8B8B8B" stroke-linejoin="round"/><path d="M37.5 32.0101V27.7919M28.5364 20.9374L28.589 20.832C28.9019 20.2062 29.3349 19.6409 29.9161 19.2514C30.1559 19.0907 30.4142 18.9338 30.6454 18.8283C31.4178 18.4758 31.91 18.3883 32.7545 18.301C33.0531 18.2701 33.4349 18.2692 33.7486 18.2765C34.1392 18.2855 34.524 18.3635 34.8947 18.4871L35.0797 18.5488C35.6311 18.7326 36.1321 19.0422 36.5431 19.4532L36.6331 19.5432C36.858 19.7681 37.0453 20.0279 37.1875 20.3124V20.3124C37.393 20.7234 37.5 21.1766 37.5 21.6361V23.0464M37.5 27.7919C37.5 27.7919 36.6462 29.173 35.9182 29.901C35.4673 30.3519 34.7659 30.851 34.2973 31.1657C33.976 31.3814 33.6236 31.5446 33.2565 31.667L32.9776 31.76C32.4806 31.9256 31.9602 32.0101 31.4364 32.0101H31.3247C30.8748 32.0101 30.4279 31.9375 30.001 31.7953L29.771 31.7186C29.3058 31.5635 28.8831 31.3023 28.5364 30.9555L28.4979 30.9171C28.1764 30.5956 27.9342 30.2037 27.7905 29.7725V29.7725C27.5901 29.1714 27.5901 28.5215 27.7905 27.9204V27.9204C27.9342 27.4892 28.1764 27.0973 28.4979 26.7758L28.5364 26.7373C28.8831 26.3906 29.3058 26.1293 29.771 25.9743L30.6454 25.6828L32.7545 25.1555L34.8636 24.6283L35.3257 24.4742C36.0621 24.2288 36.7312 23.8152 37.2801 23.2664L37.5 23.0464M37.5 27.7919V23.0464" stroke="#8B8B8B" stroke-linecap="round"/><path d="M8.5 31.9818L11.1657 25.1273M24.8455 31.9818L22.1798 25.1273M22.1798 25.1273L17.7116 13.6376C17.5621 13.2532 17.192 13 16.7796 13H16.5659C16.1535 13 15.7834 13.2532 15.6339 13.6376L11.1657 25.1273M22.1798 25.1273H11.1657" stroke="#8B8B8B" stroke-linecap="round"/></svg>'
            // },
            {
                type: 'texteditor',
                placeholder: 'Текст',
                description: 'Многострочное текстовое поле',
                svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.244 13.081L3.187 10.278H6.66L7.604 13.081H8.86L5.54 3.75H4.322L1 13.081H2.244ZM4.944 5.158L6.34 9.314H3.51L4.91 5.158H4.944ZM14.09 12.185H14.125V13.081H15.253V8.125C15.253 6.615 14.139 5.78 12.607 5.78C10.871 5.78 10.017 6.696 9.941 7.954H11.049C11.117 7.236 11.644 6.764 12.566 6.764C13.537 6.764 14.084 7.284 14.084 8.228V8.959H12.19C10.543 8.966 9.668 9.759 9.668 11.017C9.668 12.336 10.625 13.197 12.013 13.197C13.073 13.197 13.729 12.767 14.091 12.186L14.09 12.185ZM12.327 12.22C11.575 12.22 10.871 11.823 10.871 10.976C10.871 10.326 11.295 9.861 12.279 9.861H14.084V10.695C14.084 11.591 13.332 12.22 12.327 12.22Z" fill="currentColor"/></svg>'
            },
            {
                type: 'image',
                placeholder: 'Изображение',
                description: 'Загрузка изображения',
                svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.00195 5.5C6.00195 5.89782 5.84392 6.27936 5.56261 6.56066C5.28131 6.84196 4.89978 7 4.50195 7C4.10413 7 3.7226 6.84196 3.44129 6.56066C3.15999 6.27936 3.00195 5.89782 3.00195 5.5C3.00195 5.10218 3.15999 4.72064 3.44129 4.43934C3.7226 4.15804 4.10413 4 4.50195 4C4.89978 4 5.28131 4.15804 5.56261 4.43934C5.84392 4.72064 6.00195 5.10218 6.00195 5.5Z" fill="currentColor"/><path d="M2.00195 1C1.47152 1 0.962812 1.21071 0.58774 1.58579C0.212667 1.96086 0.00195313 2.46957 0.00195312 3V13C0.00195313 13.5304 0.212667 14.0391 0.58774 14.4142C0.962812 14.7893 1.47152 15 2.00195 15H14.002C14.5324 15 15.0411 14.7893 15.4162 14.4142C15.7912 14.0391 16.002 13.5304 16.002 13V3C16.002 2.46957 15.7912 1.96086 15.4162 1.58579C15.0411 1.21071 14.5324 1 14.002 1H2.00195ZM14.002 2C14.2672 2 14.5215 2.10536 14.7091 2.29289C14.8966 2.48043 15.002 2.73478 15.002 3V9.5L11.225 7.553C11.1312 7.50602 11.025 7.48973 10.9215 7.50642C10.8179 7.52311 10.7222 7.57194 10.648 7.646L6.93795 11.356L4.27795 9.584C4.18191 9.52006 4.06672 9.4913 3.95189 9.5026C3.83707 9.5139 3.72969 9.56456 3.64795 9.646L1.00195 12V3C1.00195 2.73478 1.10731 2.48043 1.29485 2.29289C1.48238 2.10536 1.73674 2 2.00195 2H14.002Z" fill="currentColor"/></svg>'
            },
            // {
            //     type: 'gallery',
            //     placeholder: 'Галерея',
            //     description: 'Галерея изображений',
            //     svg: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16"><path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/><path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/></svg>'
            // },
            {
                type: 'video',
                placeholder: 'Видео',
                description: 'Ссылка YouTube или iframe код',
                svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0L15 0C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15V1ZM4 1V7H12V1H4ZM12 9H4V15H12V9ZM1 1V3H3V1H1ZM3 4H1V6H3V4ZM1 7V9H3V7H1ZM3 10H1V12H3V10ZM1 13V15H3V13H1ZM15 1H13V3H15V1ZM13 4V6H15V4H13ZM15 7H13V9H15V7ZM13 10V12H15V10H13ZM15 13H13V15H15V13Z" fill="currentColor"/></svg>'
            },
            {
                type: 'audio',
                placeholder: 'Аудио',
                description: 'Загрузка аудиозаписи',
                svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 13C6 14.105 4.88 15 3.5 15C2.12 15 1 14.105 1 13C1 11.896 2.12 11 3.5 11C4.88 11 6 11.896 6 13ZM15 11C15 12.105 13.88 13 12.5 13C11.12 13 10 12.105 10 11C10 9.895 11.12 9 12.5 9C13.88 9 15 9.895 15 11Z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14 11V2H15V11H14ZM6 3V13H5V3H6Z" fill="currentColor"/><path d="M5 2.90499C5 2.65709 5.09208 2.41802 5.25838 2.23416C5.42468 2.05031 5.65334 1.93478 5.9 1.90999L13.9 1.10999C14.0391 1.09602 14.1795 1.11134 14.3123 1.15499C14.445 1.19863 14.5672 1.26961 14.6708 1.36336C14.7745 1.45712 14.8573 1.57155 14.914 1.69929C14.9707 1.82703 15 1.96524 15 2.10499V2.99999L5 3.99999V2.90499Z" fill="currentColor"/></svg>'
            },
            {
                type: 'file',
                placeholder: 'Файл',
                description: 'Загрузка любого файла',
                svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 4.5V14C14 14.5304 13.7893 15.0391 13.4142 15.4142C13.0391 15.7893 12.5304 16 12 16H4C3.46957 16 2.96086 15.7893 2.58579 15.4142C2.21071 15.0391 2 14.5304 2 14V2C2 1.46957 2.21071 0.960859 2.58579 0.585786C2.96086 0.210714 3.46957 0 4 0L9.5 0L14 4.5ZM11 4.5C10.6022 4.5 10.2206 4.34196 9.93934 4.06066C9.65804 3.77936 9.5 3.39782 9.5 3V1H4C3.73478 1 3.48043 1.10536 3.29289 1.29289C3.10536 1.48043 3 1.73478 3 2V14C3 14.2652 3.10536 14.5196 3.29289 14.7071C3.48043 14.8946 3.73478 15 4 15H12C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14V4.5H11Z" fill="currentColor"/></svg>'
            },
            {
                type: 'poll',
                placeholder: 'Опрос',
                description: 'Создать опрос',
                svg: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/></svg>'
            }
        ];

        let fieldsFromUser = Array.isArray(this.props.fields) ? this.props.fields : [];

        fieldsFromUser = fieldsFromUser.map(item => {
            return {
                type: item.name,
                placeholder: item.placeholder,
                description: item.description,
                src: item.src,
                svg: item.svg,
            };
        });

        let fields = this.getFieldsDB(),
            fieldsToCreate = [...this.fieldsToCreateAllow(), ...fieldsFromUser];

        if (fields.length == 0 && fieldsToCreate.length) {
            let idGenerate = this.generateId();

            fields.push({
                name: this.props.name + '[' + fieldsToCreate[0].type + '_' + idGenerate + ']',
                id: idGenerate,
                type: fieldsToCreate[0].type,
                value: ''
            });
        }

        this.state = {
            fields: fields,
            fieldsToCreate: fieldsToCreate,
            screen: null
        }
    }

    fieldsToCreateAllow() {
        if (Array.isArray(this.props.defaultFields)) {
            let result = [];

            this.props.defaultFields.forEach(type => {
                for (let i = 0; i < this.fieldsToCreate.length; i++) {
                    let field = this.fieldsToCreate[i];

                    if (field.type == type) {
                        result.push(field);
                    }
                }
            });

            if (result.length) {
                this.fieldsToCreate = result;
            }
        }

        return this.fieldsToCreate;
    }

    getFieldsDB() {
        let fields = [];

        if (Array.isArray(this.props.value)) {
            this.props.value.forEach(item => {
                let name = Object.keys(item)[0],
                    nameArr = name.split('_');

                fields.push({
                    name: this.props.name + '[' + name + ']',
                    id: nameArr[1],
                    type: nameArr[0],
                    value: item[name]
                });
            });
        } else if (typeof this.props.value === 'string') {
            let idGenerate = this.generateId();

            fields.push({
                name: this.props.name + '[texteditor_' + idGenerate + ']',
                id: idGenerate,
                type: 'texteditor',
                value: this.props.value
            });
        }

        return fields;
    }

    generateId() {
        return new String(Math.random()).replace('.', '');
    }

    addField = (type, id) => {
        document.body.click();

        var idGenerate = this.generateId(),
            name = this.props.name + '[' + type + '_' + idGenerate + ']';

        this.setState(prevState => {
            let item = {
                name: name,
                type: type,
                id: idGenerate
            };

            if (id !== null) {
                let indexPaste = null;

                prevState.fields.forEach((element, index) => {
                    if (element.id === id) {
                        indexPaste = index;
                    }
                });

                prevState.fields.splice(indexPaste, 0, item);
            } else {
                prevState.fields.push(item);
            }

            return {
                fields: prevState.fields
            };
        }, () => {
            let input = document.querySelector('[name="' + name + '"]');

            if (input) {
                if (input.getAttribute('type') == 'file') {
                    input.click();
                } else {
                    input.focus();
                }
            }
        });
    }

    delField = (id) => {
        let inputs = document.querySelectorAll('[name*="' + id + '"]'),
            isValue = null;

        if (inputs.length) {
            inputs.forEach(input => {
                if (input.value) {
                    isValue = true;
                }
            });
        }

        if (isValue) {
            if (!confirm('Подтверждаете удаление?')) {
                return false;
            }
        }

        this.setState(prevState => {
            prevState.fields = prevState.fields.filter((field) => id !== field.id);

            return {
                fields: prevState.fields
            };
        });
    }

    dragField = (id, up) => {
        this.setState((prevState) => {
            let indexPaste = null,
                fieldPaste = null;

            prevState.fields.forEach((field, index) => {
                if (field.id === id) {
                    indexPaste = index;
                    fieldPaste = field;
                }
            });

            prevState.fields.splice(up ? indexPaste - 1 : indexPaste + 2, 0, fieldPaste);
            prevState.fields.splice(up ? indexPaste + 1 : indexPaste, 1);

            return {
                fields: prevState.fields
            };
        });
    }

    onMouseEnter = (field) => {
        this.setState({
            screen: this.props.screen && this.props.screen[field.type]
                ? this.props.screen[field.type] : null
        });
    }

    onMouseLeave = () => {
        this.setState({
            screen: null
        });
    }

    getActions(id, index) {
        let buttons = [],
            isEnd = index === undefined,
            isPenult = (this.state.fields.length - 1) === index,
            isFirst = index === 0;

        if (isEnd) {
            buttons.push(<div key={'btn_' + id + '_' + index + '_empty'} className="desc small opacity-75">Нажми на плюсик, чтобы добавить необходимый контент</div>);
        } else {
            buttons.push(<div key={'btn_' + id + '_' + index + '_add'} className="multiple_block_icon" onClick={() => this.delField(id)}><svg className="delete" viewBox="0 0 14 14"><path d="M13.5000308,3.23952 C13.5000308,4.55848168 11.9230308,12.0493 11.9230308,12.0782 C11.9230308,12.6571 9.73825083,14 7.04165083,14 C4.34504083,14 2.16025083,12.6571 2.16025083,12.0782 C2.16025083,12.0541 0.5,4.55799105 0.5,3.23952 C0.5,1.91780104 3.02713083,0 7.03525083,0 C11.0433308,0 13.5000308,1.9178004 13.5000308,3.23952 Z M7,2 C3.625,2 2.5,3.25 2.5,4 C2.5,4.75 3.625,6 7,6 C10.375,6 11.5,4.75 11.5,4 C11.5,3.25 10.375,2 7,2 Z"></path></svg></div>);

            if (!isFirst) {
                buttons.push(<div key={'btn_' + id + '_' + index + '_up'} className="multiple_block_icon" onClick={() => this.dragField(id, true)}><svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8.53033 1.36967C8.23744 1.07678 7.76256 1.07678 7.46967 1.36967L2.6967 6.14264C2.40381 6.43554 2.40381 6.91041 2.6967 7.2033C2.98959 7.4962 3.46447 7.4962 3.75736 7.2033L8 2.96066L12.2426 7.2033C12.5355 7.4962 13.0104 7.4962 13.3033 7.2033C13.5962 6.91041 13.5962 6.43554 13.3033 6.14264L8.53033 1.36967ZM8.75 14.9V1.9H7.25L7.25 14.9H8.75Z" /></svg></div>);
            }

            if (!isPenult) {
                buttons.push(<div key={'btn_' + id + '_' + index + 'down'} className="multiple_block_icon" onClick={() => this.dragField(id)}><svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M7.46967 14.6303C7.76256 14.9232 8.23744 14.9232 8.53033 14.6303L13.3033 9.85736C13.5962 9.56447 13.5962 9.08959 13.3033 8.7967C13.0104 8.5038 12.5355 8.5038 12.2426 8.7967L8 13.0393L3.75736 8.7967C3.46447 8.5038 2.98959 8.5038 2.6967 8.7967C2.40381 9.08959 2.40381 9.56446 2.6967 9.85736L7.46967 14.6303ZM7.25 1.1L7.25 14.1L8.75 14.1L8.75 1.1L7.25 1.1Z" /></svg></div>);
            }
        }

        let plusBtn = this.fieldsToCreate.length == 1
            ? <div onClick={() => this.addField(this.fieldsToCreate[0].type, id)} className="multiple_block_icon"><svg viewBox="0 0 16 16"><path d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path></svg></div>
            : <OverlayTrigger
                trigger="click"
                key="right"
                placement="right"
                rootClose={true}
                overlay={
                    <Popover placement="right-start" style={{ maxWidth: 176 }}>
                        <Popover.Body className="row gx-3 gy-1 bg-transparent">
                            {
                                this.state.fieldsToCreate.map((field, index) => {
                                    let screen = this.props.screen && this.props.screen[field.type]
                                        ? this.props.screen[field.type] : null,
                                        img = '';

                                    if (field.svg) {
                                        img = field.svg;
                                    } else if (field.src) {
                                        img = '<img src="' + field.src + '" />';
                                    } else if (screen) {
                                        img = '<img src="' + screen + '" />';
                                    }

                                    return <div key={'field_' + id + '_' + index} className="constructor-field col-12"
                                        onClick={() => this.addField(field.type, id)}
                                        onMouseEnter={() => this.onMouseEnter(field)}
                                        onMouseLeave={() => this.onMouseLeave()}
                                    >
                                        <div className="left" dangerouslySetInnerHTML={{ __html: img }}></div>
                                        <div className="right">
                                            <div className="name">{field.placeholder}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </Popover.Body>
                    </Popover>
                }
            ><div className="multiple_block_icon"><svg viewBox="0 0 16 16"><path d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path></svg></div></OverlayTrigger>;

        return <div className={'constructor-actions ' + (isEnd ? 'end' : 'mb-2')}>
            {plusBtn}
            {buttons}
        </div>
    }

    getField(field) {
        let result = null;

        if (field.type == 'string') {
            result = <Input name={field.name} value={field.value} />
        } else if (field.type == 'texteditor') {
            result = <TextEditor name={field.name} value={field.value} />
        } else if (field.type == 'image') {
            result = <File name={field.name} value={field.value} />
        } else if (field.type == 'video') {
            result = <Input name={field.name} value={field.value} type="video" />
        } else if (field.type == 'audio') {
            result = <File name={field.name} value={field.value} />
        } else if (field.type == 'file') {
            result = <File name={field.name} value={field.value} />
        } else if (field.type == 'poll') {
            result = <Poll name={field.name} value={field.value} {...(this.props.options && this.props.options.poll ? this.props.options.poll : null)} />
        } else {
            result = <CustomField field={field} fields={this.props.fields} />
        }

        return result;
    }

    getHiddenFields() {
        let result = [];

        this.state.fields.map(field => {
            result.push(field.id);
        });

        return <>
            <input type="hidden" name={this.props.name + '_sort'} value={result.join(',')} />
            {
                this.state.fields.length == 0
                    ? <input type="hidden" name={this.props.name} value="" />
                    : ''
            }
        </>
    }

    render() {
        return <fieldset className="notLegend">
            {
                this.state.fields.map((field, index) => {
                    return <div
                        key={field.id}
                        className="form-group mb-3"
                    >
                        {this.getActions(field.id, index)}
                        {this.getField(field)}
                    </div>
                })
            }
            {
                this.getActions(null)
            }
            {
                this.getHiddenFields()
            }
        </fieldset>
    }

}

export default Constructor;