class Header {
    constructor() {
        this.events();
    }

    events() {
        this.scrollPage();
    }

    scrollPage() {
        var scrollPos = 0;

        $(window).on("scroll", () => {
            var st = $(window).scrollTop();

            if (st > 100) {
                if (st > scrollPos) {
                    $(".header").addClass("header-up");
                } else {
                    $(".header").removeClass("header-up");
                }
            } else {
                $(".header").removeClass("header-up");
            }

            scrollPos = st;
        });
    }
}

export default new Header();