class Theme {
    constructor() {
        this.events();
    }

    events() {
        $(document).on('click', '[switch-to-dark]', () => {
            this.switcher();
        });
    }

    switcher() {
        let theme = "";

        if ($.cookie('theme') == 'light') {
            theme = "dark";
            $('body').attr('dark', true);
        } else {
            theme = "light";
            $('body').removeAttr('dark');
        }

        $.cookie('theme', theme, { expires: 7, path: '/' });

        $("#theme-link").attr("href", '/css/V2/theme/' + theme + '.css');
    }
}

new Theme();