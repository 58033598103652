$(document)
    .on('click', '[data-bs-target="#searchModal"]', () => {
        $('#searchModal [name="query"]').val('').trigger('change');
    })
    .on('click', '[search-close]', () => {
        $('#searchModal [name="query"]').val('').trigger('change');
    })
    .on('click', '.tag', (e) => {
        let tag = $(e.currentTarget).text();

        $('#searchModal').modal('show');
        $('#searchModal [name="query"]').val(tag).trigger('change');
    });

const isModal = () => {
    return $('body.modal-open').length;
}

const getQuery = () => {
    var query = $.trim(isModal() ? $('#searchModal [name="query"]').val() : $('header [name="query"]').val());

    if (query.length > 0) {
        $(document).find('[cancel-search]').show();
    } else {
        $(document).find('[cancel-search]').hide();
    }

    return query.length > 0 ? query : '';
}

items.create({
    name: 'search',
    html: (html, data) => {
        var words = [];

        if (getQuery() != '') {
            words.push(getQuery());
        }

        if (words.length) {
            $.each(words, (i, word) => {
                data.name = data.name.replace(new RegExp(word, "gi"), '<strong>' + word + '</strong>');

                if (data.tags) {
                    data.tags = data.tags.replace(new RegExp(word, "gi"), '<strong>' + word + '</strong>');
                }
            });

            html.find('[html="name"]').html(data.name);

            if (data.tags) {
                html.find('[html="tags"]').html(data.tags);
            }
        }

        return html;
    },
    onPrint: response => {
        if (response.data.length == 0) {
            $('[items-empty-search]').html(getQuery() ? 'По запросу «' + getQuery() + '» ничего не найдено.' : 'Введите поисковой запрос');
        }
    }
});