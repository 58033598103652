class Flip {
    constructor() {
        this.events();
    }

    events() {
        $(document)
            .on('click', '[flip-menu]', (e) => {
                this.toggleFlip(e);
            })
            .on('click', '[flip-menu-backdrop]', (e) => {
                this.closeFlip();
            })
            .on('show.bs.modal', () => {
                if ($('body').hasClass('modal-open')) {
                    this.closeFlip();
                }
            });
    }

    toggleFlip() {
        let elem = $('.flip-menu');

        elem.toggleClass('active');
        if (elem.hasClass('active')) {
            this.openFlip();
        } else {
            this.closeFlip();
        }
    }

    openFlip() {
        $('body').addClass('flip-menu-open overflow-hidden');
        $('.mobile-menu').addClass('shadow');
        $('.flip-menu-backdrop').addClass('active');
        $('.flip-menu').addClass('shadow active');
    }

    closeFlip() {
        $('.flip-menu-backdrop').removeClass('active');
        $('.flip-menu').removeClass('shadow active');
        $('.mobile-menu').removeClass('shadow');
        $('body').removeClass('flip-menu-open overflow-hidden');
    }
}

new Flip();